import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Button, ListGroup, Row, Col, Form } from 'react-bootstrap'

const Product = ({ product }) => {

    const [qty, setQty] = useState(1)

    const navigate = useNavigate()

    const addToCartHandler = (id) => {
        navigate(`/cart/${id}?qty=${qty}`)
    }

    return (
        <Card className="my-3 p-3 rounded">

            <Card.Body>
                <Card.Title as='h3'>
                    <strong>{product.intern_name}</strong>
                </Card.Title>

                <Card.Text as='div'>
                    <p>{product.intern_description}</p>
                </Card.Text>

                <Card.Text as='h4'>
                    CHF {product.sale_price && product.sale_price.slice(0, -4)}
                </Card.Text>

            </Card.Body>

            <ListGroup.Item>
                <Row>
                    <Col className='col-aligned-center'>Menge</Col>
                    <Col>
                        <Form.Control as='input' value={qty} onChange={(e) => setQty(e.target.value) }>
                        </Form.Control>
                    </Col>
                </Row>
            </ListGroup.Item>

            <Button className='btn-block' type='button' onClick={() => addToCartHandler(product.id)}>hinzufügen</Button>
        
        </Card>
    )
}

export default Product
