import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Welcomescreen from './screens/Welcomescreen'
import Header from './components/Header'
import Cartscreen from './screens/Cartscreen';
import Productscreen from './screens/Productscreen';

function App() {
  return (
    <Router>
      <Header />
      <main className='container'>
        <Container>
        <Routes>
          <Route path='/' element={<Welcomescreen />} />
          <Route path='/main' element={<Productscreen />} />
          <Route path='/cart/:id/*' element={<Cartscreen />} />
          <Route path='/cart/*' element={<Cartscreen />} />
        </Routes>
        </Container>
      </main>
    </Router>
  );
}

export default App;
