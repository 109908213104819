import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, Nav, Container, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../actions/userActions'
import { HiUser, HiMap, HiShoppingCart } from "react-icons/hi2";


const Header = () => {

    const dispatch = useDispatch();

    const userLogin = useSelector(state => state.userLogin);
    const { userInfo } = userLogin;

    const logoutHandler = () => {
        dispatch(logout())
    }

    useEffect(() => {
    }, [dispatch, userInfo])

    return (
        <header>
            <Navbar bg="dark" variant='dark' expand="lg" collapseOnSelect>
                <Container>
                    <LinkContainer to='/'>
                        <Navbar.Brand ><img src="genusswein_Logo_neg.png" width="300" className="d-inline-block align-top" alt="React Bootstrap logo"
            /></Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className='justify-content-end'>
                        <Nav className='ml-auto'>
                            {userInfo ? (
                                <>
                                <Nav.Link className='pe-4' href="/main"><HiMap /> Produkte</Nav.Link>
                                <Nav.Link className='pe-4' href="/cart"><HiShoppingCart /> Checkout</Nav.Link>
                                <Nav.Link onClick={() => logoutHandler()}>Logout</Nav.Link>
                                </>
                            ) : 
                            <Button><a style={{color:'white'}} href='/api/login'><HiUser /> Login</a></Button>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header
