import axios from "axios"
import { PRODUCT_LOAD_FAIL, PRODUCT_LOAD_REQUEST, PRODUCT_LOAD_SUCCESS } from "../constants/productConstants"

export const loadProductsFromBexio = () => async (dispatch) => {
   
    try {

        dispatch({
            type: PRODUCT_LOAD_REQUEST
        })

        const { data } = await axios.get('/api/products')
        
        dispatch({
            type: PRODUCT_LOAD_SUCCESS,
            payload: data
        })
        
    } catch (error) {
        dispatch({ 
            type: PRODUCT_LOAD_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }
}