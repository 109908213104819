import { 
    PRODUCT_LOAD_REQUEST, 
    PRODUCT_LOAD_SUCCESS, 
    PRODUCT_LOAD_FAIL
} from '../constants/productConstants'

export const bexioProductsReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_LOAD_REQUEST:
            return { loading: true }
        case PRODUCT_LOAD_SUCCESS:
            return { loading: false, products: action.payload }
        case PRODUCT_LOAD_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}