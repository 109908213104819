import axios from 'axios'
import { PLACE_ORDER_REQUEST, PLACE_ORDER_SUCCESS, PLACE_ORDER_FAIL } from '../constants/orderConstants'


export const placeOrderNew = ({ orderItems, paymentType }) => async (dispatch) => {
    
    try {

        const newOrder = orderItems.map((item, index) => ({
            article_id: item.product,
            unit_id: 1,
            amount: item.qty,
            tax_id: 44, // für 8.1% MwSt. ab 2024
            //tax_id: 29, // alte Nummer für 8.1% MwSt.
            text: item.name,
            unit_price: item.price
          }));

        dispatch({
            type: PLACE_ORDER_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'paymentType': paymentType
            }
        }

        const { data } = await axios.post('/api/order/create', newOrder, config)
        dispatch({
            type: PLACE_ORDER_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({ 
            type: PLACE_ORDER_FAIL, 
            payload: error.response && error.response.data.message ? error.response.data.message : error.message 
        })
    }
}