import axios from 'axios'
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_RESET } from '../constants/cartConstants'

export const addToCart = (id, qty, price) => async (dispatch, getState) => {
    const { data } = await axios.get(`/api/product/${id}`)

    dispatch({
        type: CART_ADD_ITEM,
        payload: {
            product: data.id,
            name: data.intern_name,
            price: price ? price : data.sale_price.slice(0, -4),
            qty: qty
        }
    })

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))

}

export const removeFromCart = (id) => async (dispatch, getState) => {
    
    dispatch({
        type: CART_REMOVE_ITEM,
        payload: id
    })

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))

}

export const resetCart = () => async (dispatch) => {
    
    dispatch({
        type: CART_RESET
    })

    localStorage.removeItem('cartItems');

}