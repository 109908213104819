import React from 'react'
import { Form } from 'react-bootstrap'
import { BsSearch } from "react-icons/bs";

const SearchBar = ({ products, setSearchResults }) => {
  
    const handleSubmit = (e) => {
        e.preventDefault()
    }
  
    const handleSearchChange = (e) => {
        if(!e.target.value) return setSearchResults(products)
        
        const resultArray = products.filter(product => 
            product.intern_name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()))
        
        setSearchResults(resultArray)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <input 
                type="text" 
                className="inputSearch" 
                placeholder="Produkt suchen..." 
                name="search" 
                onChange={handleSearchChange} />
                <button className='btn'><BsSearch /></button>
        </Form>
  )
}

export default SearchBar