import React from 'react'
import Meta from '../components/Meta'
import {Button} from 'react-bootstrap'

const Welcomescreen = () => {

    return (
        <>
        <Meta />
            <h1>genusswein-Shop</h1>
            <p><br /><strong>Willkommen bei genusswein.ch!</strong><br />Wir sind ein unabhängiger Weinshop, der sich auf die Verkostung und den Verkauf von hochwertigen Weinen aus der ganzen Welt spezialisiert hat. Ob Sie nach einem besonderen Rotwein für ein romantisches Abendessen oder nach einem leckeren Weißwein für eine Party suchen – wir haben bestimmt das Richtige für Sie. Stöbern Sie in unserem Sortiment und lassen Sie sich von unserer Leidenschaft für guten Wein inspirieren.</p>
            <p>Hier sind Sie aber leider auf einer unserer internen Applikationen gelandet. Gerne leiten wir Sie weiter unter folgendem Button gelangen Sie in unseren Webshop.</p>
            <Button href='https://genusswein.ch'>Weiter zum Shop</Button>
        </>
    )
}

export default Welcomescreen
