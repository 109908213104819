import { PLACE_ORDER_REQUEST, PLACE_ORDER_SUCCESS, PLACE_ORDER_FAIL } from '../constants/orderConstants'

export const orderCreateReducer = (state = {}, action) => {
    switch(action.type) {
        case PLACE_ORDER_REQUEST:
            return { loading: true }
        case PLACE_ORDER_SUCCESS:
            return { loading: false, success: true, order: action.payload }
        case PLACE_ORDER_FAIL: 
            return { loading: false, error: action.payload }
        default:
            return state
    }
}