import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Form, Button, Card } from 'react-bootstrap'
import Message from '../components/Message'
import Meta from '../components/Meta'
import Loader from '../components/Loader'
import { addToCart, removeFromCart, resetCart } from '../actions/cartActions'
import { fetchUser, logout, tokenVerify } from '../actions/userActions'
import { placeOrderNew } from '../actions/orderActions'
import { HiX } from "react-icons/hi";

const Cartscreen = () => {
    
    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    const [paymentType, setPaymentType] = useState(0)

    const productID = params.id;
    const qty = location.search ? Number(location.search.split('=')[1]) : 1

    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    const userLogin = useSelector(state => state.userLogin);
    const { loading, error, userInfo } = userLogin

    const orderCreate = useSelector(state => state.orderCreate)
    const { order, error:orderError } = orderCreate;

    useEffect(() => {
        dispatch(fetchUser())
    },[])

    useEffect(() => {
        if (productID) {
            dispatch(addToCart(productID, qty))
        }
    }, [dispatch, productID, qty, order])

    useEffect(() => {
        if (userInfo) {
            const decodedJwt = tokenVerify(userInfo.token);
            if (decodedJwt.exp * 1000 < Date.now()) {
                dispatch(logout());
                navigate('/')
            }
        }
    },[dispatch, userInfo])

    const removeFromCartHandler = (id) => {
        dispatch(removeFromCart(id))
    }

    const placeOrderHandler = () => {
        dispatch(placeOrderNew({
            orderItems: cart.cartItems,
            paymentType
        }))
        dispatch(resetCart())
        navigate(`/main`)
    } 

    return (
        <Row>
            <Col md={8}>
                <Meta title='Warenkorb | genusswein Shop' description='Warenkorb der POS-Applikation' />
                <h1 className='pb-4'>Warenkorb</h1>
                {loading && <Loader />}
                {error || orderError ? <Message variant='error' children={error || orderError}></Message> : ''}
                {cartItems.length === 0 ? <Message>Ihr Warenkorb ist leer <Link to='/'>Zurück</Link></Message> : (
                <ListGroup variant='flush'>
                    {cartItems.map(item => (
                        <ListGroup.Item key={item.product}>
                            <Row>
                                <Col md={5}>
                                   <strong>{item.name}</strong>
                                </Col>
                                <Col md={1} className='input-price'>
                                    {item.qty}x
                                </Col>
                                <Col md={3} className='input-price'>
                                        <Form.Label className='pe-2'>CHF</Form.Label>
                                        <Form.Control as='input' value={item.price} onChange={(e) => dispatch(addToCart(item.product, item.qty, e.target.value))}>
                                        </Form.Control>
                                </Col>
                                <Col md={2}>
                                    <Button type='button' variant='light' onClick={() => removeFromCartHandler(item.product)}><i className='fas fa-trash' /><HiX /></Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
                )}
            </Col>
            <Col md={4}>
             <Card>
                 <ListGroup variant='flush'>
                    <ListGroup.Item>
                        <h2>Total ({cartItems.reduce((acc, item) => acc + item.qty, 0 )}) Flaschen</h2>
                        <p style={{color: '#063251', fontSize: '1.5rem'}}>CHF {cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2)}</p>
                    </ListGroup.Item>
                    <Form onChange={(e) => setPaymentType(e.target.value)} className='mt-4'>
                        <Form.Check             
                            inline
                            label='Barzahlung'
                            name="rechnungTyp"
                            type='radio'
                            value='2'
                            id='rechnungTyp1'/>
                        <Form.Check             
                            inline
                            label='EC Karte'
                            name="rechnungTyp"
                            type='radio'
                            value='3'
                            id='rechnungTyp2'/>
                    </Form>
                    <ListGroup.Item>
                        <Button type='button' className='btn' disabled={cartItems.length === 0 || paymentType === 0} onClick={placeOrderHandler}>Rechnung in Bexio erstellen</Button>
                    </ListGroup.Item>
                 </ListGroup>
             </Card>
            </Col>
        </Row>
    )
}

export default Cartscreen