import React from 'react'
import Product from '../components/Product'
import { Col, Row } from 'react-bootstrap'


const ProductList = ({searchResults}) => {
  
  const results = searchResults.map(product => 
    <Col key={product.id} md={3}>
        <Product key={product.id} product={product}/>
    </Col>
    )
  
  const content = results?.length ? results : <article><p>No matching products</p></article>
  
    return (
        <main>
            <Row>
                {content}
            </Row>
        </main>
    )
}

export default ProductList